import React from 'react';
import Icon from 'components/icons/Icon';

import { ReactComponent as ActivesIcon } from 'assets/icons/actives.svg';

const ActivesOutlined = (props) => (
  <Icon {...props} component={ActivesIcon} />
);

export default ActivesOutlined;
