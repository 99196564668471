import React from 'react';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import Icon from 'components/icons/Icon';

const Close = (props) => (
  <Icon {...props} component={CloseIcon} />
);

export default Close;
