import request from './request';

/**
 * Get all companies
 * @param {Object} options
 */
export const get = async ({ filters }) => (
  request.authorized().get('/models', {
    params: {
      ...filters && ({ filter: JSON.stringify(filters) }),
    },
  })
);

export const getOne = async ({ id, filters }) => (
  request.authorized().get(`/models/${id}`, {
    params: {
      ...filters && ({ filter: JSON.stringify(filters) }),
    },
  })
);

export const count = async ({ where }) => (
  request.authorized().get('/models/count', {
    params: {
      where: JSON.stringify(where),
    },
  })
);

export const edit = async ({ id, value }) => request.authorized().patch(`/models/${id}`, { name: value });
export const del = async ({ id }) => request.authorized().delete(`/models/${id}`);

export const create = async (body) => request.authorized().post('/models', body);
