import request from './request';

export const get = async ({ filters } = {}) => (
  request.authorized().get('/users', {
    params: {
      ...filters && ({ filter: JSON.stringify(filters) }),
    },
  })
);

export const create = async (body) => request.authorized().post('/users', body);

export const patch = async (body, id) => request.authorized().patch(`/users/${id}`, body);

export const del = async (id) => request.authorized().delete(`/users/${id}`);
