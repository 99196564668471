// eslint-disable-next-line import/no-cycle
import { CompaniesAPI } from 'api';
import request from './request';

export const create = async ({ body }) => request.authorized().post('/tower-stocks', body);

export const patch = async ({ body, id }) => request.authorized().patch(`/tower-stocks/${id}`, body);

export const get = async ({ filters } = {}) => request.authorized().get('/tower-stocks', {
  params: {
    ...filters && ({ filter: JSON.stringify(filters) }),
  },
});

export const remove = async (id) => request.authorized().delete(`/tower-stocks/${id}`);

export const fetchData = async () => {
  const data = [];
  let manufacturers = [];
  let companies = [];
  let response;

  // -- STATUS OPTIONS

  data.statusOptions = [
    { value: 'nova', label: 'Nova' },
    { value: 'usada', label: 'Usada' },
  ];

  // -- MANUFACTURES OPTIONS

  response = await get();
  response.data.forEach((stockedTower) => {
    manufacturers = [...manufacturers, {
      value: stockedTower.manufacturer,
      label: stockedTower.manufacturer,
    }];
  });
  data.manufacturersOptions = manufacturers.filter(
    (v, i, a) => a.findIndex((t) => (t.label === v.label)) === i,
  );

  // -- STOCKED TOWERS

  response = await get();
  data.stockedTowers = response.data;

  // -- LOCATIONS

  const companiesRes = await CompaniesAPI.get({
    where: {
      id: {
        or: data.stockedTowers.map((stockedTower) => stockedTower.company_id),
      },
    },
  });

  companies = companiesRes.data.map((company) => ({
    value: company.id,
    label: company.name,
  }));

  companies = companies.filter((company) => typeof company.value !== 'undefined');

  data.companiesOptions = companies.filter(
    (v, i, a) => a.findIndex((t) => (t.label === v.label)) === i,
  );

  return data;
};
