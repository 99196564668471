import React from 'react';
import Icon from 'components/icons/Icon';

import { ReactComponent as IncidenciesIcon } from 'assets/icons/incidencies.svg';

const IncidenciesOutlined = (props) => (
  <Icon {...props} component={IncidenciesIcon} />
);

export default IncidenciesOutlined;
