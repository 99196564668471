import request from './request';

export const create = async (body) =>
  request.authorized().post('/reports', body);

export const postImportedData = async (formData) =>
  request.authorized().post('/treated-data', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
export const checkReport = async (folderName, options = {}) =>
  request.authorized().get(`/reports/${folderName}`, options);
export const sendDataToAma = async (body) =>
  request.authorized().post('/data/createTxt', body);

export const downloadDataFromAma = async (body) =>
  request
    .authorized()
    .post('/data/downloadTxt', body, { responseType: 'blob' });
