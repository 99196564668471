/**
 * Colors
 */
module.exports = ({
  white: '#FFFFFF',
  black: '#111111',
  primary: '#0CAE60',
  primaryDarker: '#077350',
  bodyBackground: '#FAFAFA',
  success: '#0CC76D',
  successAlert: '#52AF61',
  warning: '#AE8F0C',
  error: '#D94848',
  info: '#0CAEAE',
  heading: '#1A1A1A',
  text: '#1A1A1A',
  textSecondary: '#757E8B',
  red: '#D94848',
  darkGray: '#757E8B',
  lightGray: '#DFDFE0',
});
