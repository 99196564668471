import React, {
  useContext,
  useState,
  useEffect,
  createContext,
} from 'react';
import { message } from 'antd';

import { ReportsAPI } from 'api';
import { download } from 'helpers/reports';

const ReportsContext = createContext();

function ReportsProvider({ children }) {
  const [folderName, setFolderName] = useState('');
  const [status, setStatus] = useState(null);

  const postReport = async (projects, stats) => {
    const res = await ReportsAPI.create({ projects, relatorioStats: stats });

    // console.log(res);

    if (!res.data) {
      message.error('Ocorreu um erro ao criar o projeto');
      return null;
    }

    setFolderName(res.data.folderName);
  };

  useEffect(() => {
    const fetchData = async () => {
      const axiosOptions = {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const res = await ReportsAPI.checkReport(folderName, axiosOptions);

      // console.log(res);

      if (res.data.byteLength > 4) {
        setStatus('finished');
        download(`${folderName}.zip`, res.data);
      } else {
        setTimeout(() => {
          fetchData();
        }, 1000);
      }
    };

    if (folderName) {
      fetchData();
      setStatus('ongoing');
    }
  }, [folderName]);

  return (
    <ReportsContext.Provider value={{ postReport, status }}>
      {children}
    </ReportsContext.Provider>
  );
}

export default ReportsProvider;
export const useReportsContext = () => useContext(ReportsContext);
