import request from './request';

/**
 * Authenticate an user
 * @param {ICredentials} userCredentials
 */
export const get = async ({ filters }) => request.authorized().get('/solicitations', {
  params: {
    filter: filters ?? JSON.stringify(filters),
  },
});

export const getOne = async ({ id, filters }) => request.authorized().get(`/solicitations/${id}`, {
  params: {
    filter: filters ?? JSON.stringify(filters),
  },
});

export const count = async ({ where }) => request.authorized().get('/solicitations/count', {
  params: {
    where: where ?? JSON.stringify(where),
  },
});

export const create = async (body) => request.authorized().post('/solicitations', body);

export const close = async ({ id, body }) => request.authorized().patch(`/solicitations/${id}`, body);

export const del = async ({ id }) => request.authorized().delete(`/solicitations/${id}`);

export const cancel = async ({ id }) => request.authorized().patch(`/solicitations/${id}`, { status: 'cancelada' });

export const fetchData = async () => {
  const { data: invoices } = await get({
    filters: {
      include: [{
        relation: 'user',
      }],
    },
  });

  const objectsUsers = invoices
    .filter((invoice) => invoice.user && invoice.user.id)
    .map((i) => ({
      label: i.user.name,
      value: i.user.id,
    }));

  const userOptions = objectsUsers.reduce((object, current) => {
    const x = object.find((inv) => inv.value === current.value);
    if (!x) {
      return object.concat([current]);
    }
    return object;
  }, []);

  return userOptions;
};
