import React from 'react';
import { ThemeProvider } from 'styled-components';
import theme from 'styles/theme';
import AuthProvider from './AuthProvider';
import AlertProvider from './AlertProvider';
import AnalysisProvider from './AnalysisProvider';
import ReportsProvider from './ReportsProvider';

function GlobalProvider({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <AlertProvider>
          <AnalysisProvider>
            <ReportsProvider>
              {children}
            </ReportsProvider>
          </AnalysisProvider>
        </AlertProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export const withGlobalProvider = (Component) => (
  (props) => (
    <GlobalProvider>
      <Component {...props} />
    </GlobalProvider>
  )
);

export default GlobalProvider;
