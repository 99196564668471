import request from './request';

export const get = async ({ filters } = {}) => (
  request.authorized().get('/locations', {
    params: {
      ...filters && ({ filter: JSON.stringify(filters) }),
    },
  })
);

export const create = async (body) => request.authorized().post('/locations', body);

export const put = async (body, id) => request.authorized().patch(`/locations/${id}`, body);
