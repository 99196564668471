export const AUTH_TOKEN_KEY = '_gaes_session';

export const USER_ID = '_user_id';

export const USER_ROLE = '_user_role';

export const ALERT_SERVER_ERROR = {
  type: 'error',
  message: 'Ops! Houve um problema com a requisição.',
};
