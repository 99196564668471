import request from './request';

/**
 * Authenticate an user
 * @param {ICredentials} userCredentials
 */
export const get = ({ filters }) => request.authorized().get('/incidences', {
  params: {
    filter: filters ?? JSON.stringify(filters),
  },
});

export const getOne = async ({ id, filters }) => request.authorized().get(`/incidences/${id}`, {
  params: {
    filter: filters ?? JSON.stringify(filters),
  },
});

export const count = async ({ where }) => (
  request.authorized().get('/incidences/count', {
    params: {
      where: where ?? JSON.stringify(where),
    },
  })
);

export const close = (id) => (
  request.authorized().patch(`/incidences/${id}`, {
    status: 'fechada',
  })
);

export const deleteOne = (id) => (
  request.authorized().delete(`/incidences/${id}`)
);
