import request from './request';

/**
 * Get all towers
 * @param {Object} options
 */
export const get = async ({ filters } = {}) => (
  request.authorized().get('/tower-types', {
    params: {
      ...filters && ({ filter: JSON.stringify(filters) }),
    },
  })
);

export const create = async (body) => (
  request.authorized().post('/tower-types', body)
);

export const edit = ({ id, value }) => (
  request.authorized().patch(`/tower-types/${id}`, { name: value })
);

export const remove = async (id) => (
  request.authorized().delete(`tower-types/${id}`)
);

export const del = async ({ id }) => (
  request.authorized().delete(`tower-types/${id}`)
);
