const stringToBytes = (str) => {
  const bytes = new Uint8Array(str.length);

  for (let i = 0; i < str.length; i += 1) {
    bytes[i] = str.charCodeAt(i);
  }

  return bytes;
};

export const download = async (filename, data) => {
  const blob = new Blob([data], { type: 'application/zip' });

  const a = document.createElement('a');
  a.setAttribute('href', window.URL.createObjectURL(blob));
  a.setAttribute('download', filename);
  a.style.display = 'none';

  document.body.appendChild(a);

  a.click();

  document.body.removeChild(a);
};
