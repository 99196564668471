import React, {
  useState, useMemo, useEffect, useContext,
} from 'react';
import Cookies from 'js-cookie';
import { AUTH_TOKEN_KEY, USER_ID, USER_ROLE } from 'variables';
import useRequest from 'hooks/use-request';
import { AuthAPI } from 'api';

const initialValues = {
  authenticated: null,
  token: null,
  role: null,
  user_id: null,
};

const AuthContext = React.createContext(initialValues);

function AuthProvider(props) {
  /** state */
  const [authenticated, setAuthenticated] = useState();
  const [role, setRole] = useState(null);
  const [token, setToken] = useState();

  useEffect(() => {
    const userToken = Cookies.get(AUTH_TOKEN_KEY);
    const userRole = Cookies.get(USER_ROLE);
    setToken(userToken);
    setAuthenticated(Boolean(userToken));
    setRole(userRole);
  }, []);

  /** login */
  const [authorization, loginRequest] = useRequest(AuthAPI.login);

  useEffect(() => {
    if (authorization) {
      Cookies.set(AUTH_TOKEN_KEY, authorization.token);
      Cookies.set(USER_ID, authorization.id);
      Cookies.set(USER_ROLE, authorization.role);
      setRole(authorization.role);
      setToken(authorization.token);
      setAuthenticated(true);
    }
  }, [authorization]);

  const login = loginRequest.fetch;

  /** logout */
  const logout = async () => {
    Cookies.remove(USER_ID);
    Cookies.remove(AUTH_TOKEN_KEY);
    Cookies.remove(USER_ROLE);
    setAuthenticated(false);
  };

  /** provider */
  const value = useMemo(
    () => ({ token, authenticated, role }),
    [token, authenticated, role],
  );

  return (
    <AuthContext.Provider
      value={{ ...value, login, logout }}
      {...props}
    />
  );
}

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;
