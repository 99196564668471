import React from 'react';
import Icon from 'components/icons/Icon';

import { ReactComponent as RequestsIcon } from 'assets/icons/requests.svg';

const RequestsOutlined = (props) => (
  <Icon {...props} component={RequestsIcon} />
);

export default RequestsOutlined;
