import React from 'react';

import { ReactComponent as CircleIcon } from 'assets/icons/circle.svg';
import Icon from 'components/icons/Icon';

const CircleOutlined = (props) => (
  <Icon {...props} component={CircleIcon} />
);

export default CircleOutlined;
