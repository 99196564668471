import React from 'react';
import Icon from 'components/icons/Icon';

import { ReactComponent as MapIcon } from 'assets/icons/map.svg';

const MapOutlined = (props) => (
  <Icon {...props} component={MapIcon} />
);

export default MapOutlined;
