// eslint-disable-next-line import/no-cycle
import { ProjectsAPI, LocationsAPI } from 'api';
import request from './request';
const { REACT_APP_API_URL } = process.env;

/**
 * Get all towers
 * @param {Object} options
 */
export const get = async ({ filters } = {}) =>
  request.authorized().get('/towers', {
    params: {
      ...(filters && { filter: JSON.stringify(filters) }),
    },
  });

export const getOne = async ({ id, filters } = {}) =>
  request.authorized().get(`/towers/${id}`, {
    params: {
      ...(filters && { filter: JSON.stringify(filters) }),
    },
  });

export const getInstruments = async ({ id, filters }) =>
  request.authorized().get(`/towers/${id}/instruments`, {
    params: {
      ...(filters && { filter: JSON.stringify(filters) }),
    },
  });

export const maintence = async ({ id, body }) =>
  request.authorized().patch(`/towers/${id}`, body);

export const overthrown = async ({ id, body }) =>
  request.authorized().patch(`/towers/${id}`, body);

export const del = async ({ id }) =>
  request.authorized().delete(`/towers/${id}`);

export const avgSheet = async () =>
  request.authorized().get(`/towers/anemoAvg`);
export const avgSheetByYear = async ({ year }) =>
  request.authorized().post(`/towers/anemoAvgByYear`, {
    params: {
      year: year,
    },
  });

export const count = async ({ where }) =>
  request.authorized().get('/towers/count', {
    params: {
      where: JSON.stringify(where),
    },
  });

export const noCommunication = async () =>
  request.authorized().get('/towers/noCommunication', {
    params: {
      where: '',
    },
  });
// {
//   let bodyContent = JSON.stringify({
//     startDate: startDate,
//     endDate: endDate,
//     towerId: towerId,
//   });

//   let reqOptions = {
//     url: REACT_APP_API_URL + '/towers/getDataUrl',
//     method: 'GET',
//     data: bodyContent,
//   };
//   return request.authorized().request(reqOptions);
// }
export const getRawDataFilesNames = async (
  towerId,
  startDate,
  endDate,
  total
) =>
  request.authorized().post('/towers/getDataUrl', {
    towerId: towerId,
    startDate: startDate,
    endDate: endDate,
    total: total,
  });
export const fetchData = async () => {
  const data = [];
  let projects = [];
  let locations = [];
  let status = [];
  let response;

  // -- TYPE OPTIONS

  response = await get({
    filters: {
      include: [
        {
          relation: 'tower_type',
        },
      ],
    },
  });

  let types = [];
  response.data.forEach((type) => {
    types = [
      ...types,
      { value: type.tower_type.id, label: type.tower_type.name },
    ];
  });
  data.typeOptions = types.filter(
    (v, i, a) => a.findIndex((t) => t.value === v.value) === i
  );

  // TODO - Remove typeOptions, now they are dynamic
  // data.typeOptions = [
  //   { value: 1, label: 'Torre de habilitação' },
  //   { value: 2, label: 'Torre de Parque' },
  //   { value: 3, label: 'Torre solar' },
  // ];

  // -- STATUS OPTIONS

  data.statusOptions = [
    { value: 'desativada', label: 'Desativada' },
    { value: 'manutencao', label: 'Em manutenção' },
    { value: 'com_comunicacao', label: 'Comunicando' },
    { value: 'sem_comunicacao', label: 'Sem comunicação' },
  ];
  data.statusOptionsInvoice = [
    { value: 'concluida', label: 'Fechada' },
    { value: 'pendente', label: 'Aberta' },
  ];

  data.communicationStatusOptions = [
    { value: 'active', label: 'Ativa' },
    { value: 'inactive', label: 'Inativa' },
  ];

  // -- PROJECT OPTIONS

  response = await ProjectsAPI.get();
  response.data.forEach((type) => {
    projects = [...projects, { value: type.id, label: type.name }];
  });
  data.projectOptions = projects;

  // -- TOWERS

  response = await get();
  data.towers = response.data;

  // -- LOCATIONS

  const locationsRes = await LocationsAPI.get({
    where: {
      tower_id: {
        or: data.towers.map(({ id }) => id),
      },
    },
  });

  locations = locationsRes.data.map((location) => ({
    value: location.id,
    label: location.city,
  }));

  locations = locations.filter(
    (location) => typeof location.value !== 'undefined'
  );

  data.locationOptions = locations.filter(
    (v, i, a) => a.findIndex((t) => t.label === v.label) === i
  );

  return data;
};

export const fetchStockedTowers = async () =>
  request.authorized().get('/tower-stocks', {
    params: {
      filter: {
        include: [
          {
            relation: 'company',
          },
          {
            relation: 'tower_type',
          },
        ],
      },
    },
  });

export const getTowerType = async (id) => {
  request.authorized().get(`/tower-types/${id}`);
};

export const create = async (body) =>
  request.authorized().post('/towers', body);

export const put = async (body, id) =>
  request.authorized().patch(`/towers/${id}`, body);

export const edit = async ({ body, id }) =>
  request.authorized().patch(`/towers/${id}`, body);
