import { useState, useCallback, useMemo } from 'react';

/**
 * Use request hook
 * @param {import('axios').AxiosPromise} request the request api
 * @param {Object} data the request data
 */
function useRequest(request) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetch = useCallback(
    async (fetchData = null) => {
      try {
        setError(null);
        setLoading(true);

        const response = await request(fetchData);

        setData(response.data);
        return response;
      } catch (err) {
        setData(null);
        setError(err);
        return Promise.reject(err);
      } finally {
        setLoading(false);
      }
    },
    [request],
  );

  return useMemo(
    () => [data, { loading, error, fetch, setData }],
    [data, loading, error, fetch],
  );
}

export default useRequest;
