import request from './request';

export const get = async ({ filters } = {}) => (
  request.authorized().get('/instruments', {
    params: {
      ...filters && ({ filter: filters }),
    },
  })
);

export const getOne = async ({ id, filters } = {}) => (
  request.authorized().get(`/instruments/${id}`, {
    params: {
      ...filters && ({ filter: JSON.stringify(filters) }),
    },
  })
);

export const edit = async ({ id, value }) => request.authorized().patch(`/instruments/${id}`, { name: value });

export const patch = async ({ id, body }) => request.authorized().patch(`/instruments/${id}`, body);

export const editInfo = async (id, body) => request.authorized().patch(`/instruments/${id}`, body);

export const create = async ({ body }) => request.authorized().post('/instruments', body);

/**
 * This method just refresh the 'calibrated_at' attribute of an instrument
 */

export const calibrate = async ({ id, body }) => request.authorized().patch(`/instruments/${id}`, body);

/**
 * A discarded instrument is an instrument with the flag 'deleted' equals to true
 */

export const discard = async ({ id, body }) => request.authorized().patch(`/instruments/${id}`, body);

/**
 * A deleted instrument is an instrument that belongs to a
 * company and it isn't installed in any tower
 */

export const del = async ({ id }) => request.authorized().delete(`/instruments/${id}`);

export const fetchStockedInstruments = async () => (
  request.authorized().get('/instrument-stocks', {
    params: {
      filter: {
        include: [{
          relation: 'company',
        }, {
          relation: 'last_company',
        }, {
          relation: 'instrument_type',
        }, {
          relation: 'brand',
        }, {
          relation: 'model',
        }, {
          relation: 'last_tower',
        }],
      },
    },
  })
);

export const fetchData = async () => {
  let types = [];
  let models = [];
  let brands = [];
  let towers = [];
  const data = [];

  const response = await get({
    filters: {
      include: [{
        relation: 'tower',
      }, {
        relation: 'instrument_type',
      }, {
        relation: 'brand',
      }, {
        relation: 'model',
      }],
    },
  });

  response.data.forEach((instrument) => {
    types = [...types, {
      value: instrument.instrument_type_id,
      label: instrument.instrument_type?.name,
    }];
    towers = [...towers, {
      value: instrument.tower_id,
      label: instrument.tower?.name,
    }];
    models = [...models, {
      value: instrument.model_id,
      label: instrument.model?.name,
    }];
    brands = [...brands, {
      value: instrument.brand_id,
      label: instrument.brand?.name,
    }];
  });

  data.typeOptions = types.filter(
    (v, i, a) => a.findIndex((t) => (t.label === v.label)) === i,
  );
  data.towerOptions = towers.filter(
    (v, i, a) => a.findIndex((t) => (t.label === v.label)) === i,
  );
  data.modelOptions = models.filter(
    (v, i, a) => a.findIndex((t) => (t.label === v.label)) === i,
  );
  data.brandOptions = brands.filter(
    (v, i, a) => a.findIndex((t) => (t.label === v.label)) === i,
  );

  return data;
};

export const excludeFromAvailability = async ({ id, body }) => request.authorized().patch(`/instruments/${id}`, body);
