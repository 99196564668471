import React from 'react';

import { ReactComponent as CheckmarkIcon } from 'assets/icons/checkmark.svg';
import Icon from 'components/icons/Icon';

const Checkmark = (props) => (
  <Icon {...props} component={CheckmarkIcon} />
);

export default Checkmark;
