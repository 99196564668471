import request from './request';

/**
 * Get all towers
 * @param {Object} options
 */
export const get = async ({ filters } = {}) => (
  request.authorized().get('/projects', {
    params: {
      ...filters && ({ filter: JSON.stringify(filters) }),
    },
  })
);

export const create = async (body) => request.authorized().post('/projects', body);

export const patch = async ({ id, body }) => request.authorized().patch(`/projects/${id}`, body);

export const edit = async ({ id, value }) => request.authorized().patch(`/projects/${id}`, { name: value });

export const remove = async (id) => request.authorized().delete(`/projects/${id}`);

export const del = async ({ id }) => request.authorized().delete(`projects/${id}`);
