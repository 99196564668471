import React, { useState, useContext } from 'react';

import AlertList from 'components/AlertList';

const AlertContext = React.createContext();

function AlertProvider({ children }) {
  const [alerts, setAlerts] = useState([]);

  const openAlert = (type, text) => {
    setAlerts((currentAlerts) => ([
      ...currentAlerts,
      {
        id: Date.now(),
        type,
        text,
      },
    ]));
  };

  return (
    <AlertContext.Provider value={openAlert}>
      <AlertList alerts={alerts} />
      {children}
    </AlertContext.Provider>
  );
}

export const useAlert = (type) => {
  const openAlert = useContext(AlertContext);

  return (text) => openAlert(type, text);
};

export default AlertProvider;
