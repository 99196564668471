import React from 'react';

import AlertCard from './AlertCard';
import { List } from './Alert.style';

function AlertList({ alerts }) {
  return (
    <List>
      {alerts.map((info) => <AlertCard key={info.id} {...info} />)}
    </List>
  );
}

export default AlertList;
