import React, { useState, useEffect } from 'react';

import colors from 'styles/colors';
import {
  CardBase,
  StyledCheck,
  IconContainer,
  ContentContainer,
  StyledClose,
  CloseButton,
} from './Alert.style';

const typeConfig = {
  success: {
    bgColor: colors.successAlert,
    iconBgColor: '#58BF81',
    Icon: StyledCheck,
  },
  error: {
    bgColor: colors.error,
    iconBgColor: '#E16D6D',
    Icon: StyledClose,
  },
};

function AlertCard({ type, text }) {
  const [active, setActive] = useState(null);
  const [vanishingTimeout, setVanishingTimeout] = useState(null);

  useEffect(() => {
    if (active === null) {
      setActive(true);

      setVanishingTimeout(
        setTimeout(() => setActive(false), 3000),
      );
    }
  }, [active]);

  const forceClose = () => {
    if (vanishingTimeout) clearTimeout(vanishingTimeout);
    setActive(false);
  };

  const handleMouseEnter = () => {
    if (vanishingTimeout) clearTimeout(vanishingTimeout);
  };

  const handleMouseLeave = () => {
    setVanishingTimeout(setTimeout(() => {
      setActive(false);
    }, 3000));
  };

  const IconToRender = typeConfig[type].Icon;

  return (
    <CardBase
      active={active}
      bgColor={typeConfig[type].bgColor}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <ContentContainer>
        <IconContainer bgColor={typeConfig[type].iconBgColor}>
          <IconToRender />
        </IconContainer>
        {text}
      </ContentContainer>
      <CloseButton onClick={forceClose}>
        <StyledClose />
      </CloseButton>
    </CardBase>
  );
}

export default AlertCard;
