import request from './request';

export const create = async ({ body }) => request.authorized().post('/instrument-stocks', body);

export const edit = async ({ id, body }) => request.authorized().patch(`/instrument-stocks/${id}`, body);

export const get = async ({ filters } = {}) => (
  request.authorized().get('/instrument-stocks', {
    params: {
      ...filters && ({ filter: JSON.stringify(filters) }),
    },
  })
);

/**
 * A deleted stock instrument is an instrument that belongs to a
 * tower and don't belongs to a company
 */
export const del = async ({ id }) => request.authorized().delete(`/instrument-stocks/${id}`);

export const fetchData = async () => {
  let types = [];
  let models = [];
  let brands = [];
  const data = [];

  const response = await get({
    filters: {
      include: [{
        relation: 'instrument_type',
      }, {
        relation: 'brand',
      }, {
        relation: 'model',
      }],
    },
  });

  // -- STATUS OPTIONS

  data.statusOptions = [
    { value: 'novo', label: 'Novo' },
    { value: 'velho', label: 'Velho' },
  ];

  response.data.forEach((instrument) => {
    types = [...types, {
      value: instrument.instrument_type_id,
      label: instrument.instrument_type?.name,
    }];
    models = [...models, {
      value: instrument.model_id,
      label: instrument.model?.name,
    }];
    brands = [...brands, {
      value: instrument.brand_id,
      label: instrument.brand?.name,
    }];
  });

  data.typeOptions = types.filter(
    (v, i, a) => a.findIndex((t) => (t.label === v.label)) === i,
  );
  data.modelOptions = models.filter(
    (v, i, a) => a.findIndex((t) => (t.label === v.label)) === i,
  );
  data.brandOptions = brands.filter(
    (v, i, a) => a.findIndex((t) => (t.label === v.label)) === i,
  );

  return data;
};
