import request from './request';

/**
 * Get all companies
 * @param {Object} options
 */
export const get = async ({ filters }) => (
  request.authorized().get('/data', {
    params: {
      ...filters && ({ filter: JSON.stringify(filters) }),
    },
  })
);

export const getOne = async ({ id, filters }) => (
  request.authorized().get(`/data/${id}`, {
    params: {
      ...filters && ({ filter: JSON.stringify(filters) }),
    },
  })
);

export const testFile = async ({ data }) => (
  request.authorized().post('/test-tower-csv', data)
);

export const deleteManyData = async ({ idsToDelete }) => (
  request.authorized().delete('/data/multiple', { data: { idsToDelete } })
);
