export const colorsObj = {
  Anemo: {
    max: ['#0C63AE', '#69BD78', '#ED978E', '#E6537C', '#FCCA46', '#4B4237'],
    avg: ['#0C63AE', '#69BD78', '#ED978E', '#E6537C', '#FCCA46', '#4B4237'],
    min: ['#0C63AE', '#69BD78', '#ED978E', '#E6537C', '#FCCA46', '#4B4237'],
    std: ['#0C63AE', '#69BD78', '#ED978E', '#E6537C', '#FCCA46', '#4B4237'],
  },
  WV: {
    max: ['#077350', '#B865B3', '#DC5B3A', '#A1A1A1'],
    avg: ['#077350', '#B865B3', '#DC5B3A', '#A1A1A1'],
    min: ['#077350', '#B865B3', '#DC5B3A', '#A1A1A1'],
    std: ['#077350', '#B865B3', '#DC5B3A', '#A1A1A1'],
  },
  Umid: {
    max: ['#C9913E', '#1F2041', '#E74D71'],
    avg: ['#C9913E', '#1F2041', '#E74D71'],
    min: ['#C9913E', '#1F2041', '#E74D71'],
    std: ['#C9913E', '#1F2041', '#E74D71'],
  },
  Temp: {
    max: ['#AE2B0C', '#7209B7', '#92B4A7'],
    avg: ['#AE2B0C', '#7209B7', '#92B4A7'],
    min: ['#AE2B0C', '#7209B7', '#92B4A7'],
    std: ['#AE2B0C', '#7209B7', '#92B4A7'],
  },
  Baro: {
    max: ['#D948C8', '#33202A', '#AF2BBF'],
    avg: ['#D948C8', '#33202A', '#AF2BBF'],
    min: ['#D948C8', '#33202A', '#AF2BBF'],
    std: ['#D948C8', '#33202A', '#AF2BBF'],
  },
  AneV: {
    avg: ['#570CAE', '#4361EE', '#08605F', '#FFC857', '#E9724C'],
    max: ['#570CAE', '#4361EE', '#08605F', '#FFC857', '#E9724C'],
    min: ['#570CAE', '#4361EE', '#08605F', '#FFC857', '#E9724C'],
    std: ['#570CAE', '#4361EE', '#08605F', '#FFC857', '#E9724C'],
  },
  Bat: {
    max: ['#48B8D9', '#485696', '#F26430', '#FFBA08', '#550C18'],
    avg: ['#48B8D9', '#485696', '#F26430', '#FFBA08', '#550C18'],
    min: ['#48B8D9', '#485696', '#F26430', '#FFBA08', '#550C18'],
    std: ['#48B8D9', '#485696', '#F26430', '#FFBA08', '#550C18'],
  },
  GHI: {
    max: ['#1B264F ', '#8C271E', '#30C5FF', '#A5BE00', '#F19953'],
    avg: ['#1B264F ', '#8C271E', '#30C5FF', '#A5BE00', '#F19953'],
    min: ['#1B264F ', '#8C271E', '#30C5FF', '#A5BE00', '#F19953'],
  },
  PiraDown: {
    max: ['#6369D1', '#FD3E81', '#0C0A3E'],
    avg: ['#6369D1', '#FD3E81', '#0C0A3E'],
    min: ['#6369D1', '#FD3E81', '#0C0A3E'],
    std: ['#6369D1', '#FD3E81', '#0C0A3E'],
  },
  PiraUp: {
    max: ['#FF1B1C', '#54457F', '#4C243B'],
    avg: ['#FF1B1C', '#54457F', '#4C243B'],
    min: ['#FF1B1C', '#54457F', '#4C243B'],
    std: ['#FF1B1C', '#54457F', '#4C243B'],
  },
  SPNGHI: {
    max: ['#7A542E', '#8CFF98', '#571F4E'],
    avg: ['#7A542E', '#8CFF98', '#571F4E'],
    min: ['#7A542E', '#8CFF98', '#571F4E'],
    std: ['#7A542E', '#8CFF98', '#571F4E'],
  },
  Pira: {
    max: ['#25171A', '#9000B3', '#436436'],
    avg: ['#25171A', '#9000B3', '#436436'],
    min: ['#25171A', '#9000B3', '#436436'],
    std: ['#25171A', '#9000B3', '#436436'],
  },
  CelLimpa: {
    max: ['#CA3CFF', '#1B998B', '#191308'],
    avg: ['#CA3CFF', '#1B998B', '#191308'],
    min: ['#CA3CFF', '#1B998B', '#191308'],
    std: ['#CA3CFF', '#1B998B', '#191308'],
  },
  CelSuja: {
    max: ['#941C2F', '#466365', '#FF715B'],
    avg: ['#941C2F', '#466365', '#FF715B'],
    min: ['#941C2F', '#466365', '#FF715B'],
    std: ['#941C2F', '#466365', '#FF715B'],
  },
  Default: {
    max: ['#254441', '#DB504A', '#231123'],
    avg: ['#254441', '#DB504A', '#231123'],
    min: ['#254441', '#DB504A', '#231123'],
    std: ['#254441', '#DB504A', '#231123'],
  },
};

export const chunk = (arr, size) => (
  arr.reduce((acc, e, i) => {
    if (i % size) {
      acc[acc.length - 1].push(e);
    } else {
      acc.push([e]);
    }
    return acc;
  }, [])
);

export const padZero = (string) => {
  if (string.toString().length <= 1) {
    return `0${string}`;
  }
  return string;
};

export const padDate = (date) => (
  `${padZero(date.getDate())
  }/${padZero(date.getMonth() + 1)
  }/${date.getFullYear()
  } ${padZero(date.getHours())
  }:${padZero(date.getMinutes())
  }`
);

export const getLinearData = async (
  rawData,
  dataTypes,
  instruments,
  instrumentTypes,
) => {
  const dataObj = {
    labels: [],
    datasets: [],
  };
  const labelDates = [];
  const datasetDates = Object.fromEntries(dataTypes.flatMap(({ id, labels }) => labels.map((label) => [`${id}-${label}`, []])));

  const yAxes = [];
  const instrumentsMap = new Map(instruments.map((instrument) => [instrument.id, instrument]));
  const dataTypesMap = new Map(dataTypes.map((filter) => [filter.id, filter]));

  rawData.forEach((measurement) => {
    const date = new Date(measurement.measurement_date);
    date.setHours(date.getHours() + 3);
    const formattedDate = padDate(date);
    const {
      name: instrumentName,
      instrument_type_id: instrumentTypeId,
    } = instrumentsMap.get(measurement.instrument_id);

    const filteredInstrument = dataTypesMap.get(measurement.instrument_id);

    if (!filteredInstrument) return;

    const { labels } = filteredInstrument;

    const instrumentTypeName = instrumentName.replace(/[^A-Za-z]/g, '');
    const instrumentIndex = instrumentName.replace(/[^0-9]/g, '');

    labels.forEach((type) => {
      const name = `${instrumentName}-${type}`;

      if (dataObj.labels.indexOf(formattedDate) === -1) {
        dataObj.labels.push(formattedDate);
        labelDates.push(date);
      }

      datasetDates[`${measurement.instrument_id}-${type}`].push(date);

      if (!dataObj.datasets.find((data) => data.label === name)) {
        let mainColor = colorsObj.Default[type][
          instrumentIndex ? instrumentIndex - 1 : 0
        ];
        if (colorsObj[instrumentTypeName]) {
          mainColor = colorsObj[instrumentTypeName][type][
            instrumentIndex ? instrumentIndex - 1 : 0
          ];
        }

        let unit = instrumentTypes.find((instrumentType) => (
          instrumentType.id === instrumentTypeId
        ));

        if (!unit) {
          return;
        }
        unit = unit.unitMeasurement;

        const differentUnitDataset = dataObj.datasets.find((dataset) => dataset.unit !== unit);
        const sameUnitDataset = dataObj.datasets.find((dataset) => dataset.unit === unit);
        const globalAxisConfig = {
          ticks: {
            callback: (value) => (`${value}${unit ? ` ${unit}` : ''}`),
          },
        };

        if (yAxes.length === 0) {
          yAxes.push({
            ...globalAxisConfig,
            id: 'a',
            position: 'left',
            scalePositionLeft: true,
          });
        }

        if (differentUnitDataset && !sameUnitDataset) {
          yAxes.push({
            ...globalAxisConfig,
            id: 'b',
            position: 'right',
            scalePositionLeft: false,
          });
        }

        const axisId = differentUnitDataset ? 'b' : 'a';

        dataObj.datasets.push({
          label: name,
          yAxisID: sameUnitDataset ? sameUnitDataset.yAxisID : axisId,
          unit,
          fill: false,
          lineTension: 0,
          borderWidth: 1,
          pointHitRadius: 5,
          pointRadius: 1,
          pointHoverBorderColor: 'white',
          borderColor: mainColor,
          backgroundColor: mainColor,
          data: [],
        });
      }

      const datasetIndex = dataObj.datasets.findIndex((data) => data.label === name);

      const value = Number(measurement[`${type}_value`]);
      if (instrumentTypeName === 'WV') {
        dataObj.datasets[datasetIndex].data.push(Math.abs((value.toFixed(2) % 360)));
      } else {
        dataObj.datasets[datasetIndex].data.push(value.toFixed(2));
      }
    });
  });

  const tenMinutesInMilliseconds = 10 * 60 * 1000;

  Object.keys(datasetDates).forEach((key) => {
    const [instrumentId, instrumentLabel] = key.split('-');

    const {
      name: instrumentName,
    } = instrumentsMap.get(Number(instrumentId));

    let amountOfDataSkips = 0;

    datasetDates[key].forEach((date, index) => {
      if (index === 0) return;

      const currentDate = date.getTime();
      const prevDate = datasetDates[key][index - 1].getTime();

      if (Math.abs(currentDate - prevDate) !== tenMinutesInMilliseconds) {
        const diffInMinutes = Math.abs(currentDate - prevDate) / (60 * 1000);
        const numberOfIntervalsMissing = (diffInMinutes / 10) - 1;

        const filler = Array(numberOfIntervalsMissing).fill(null);

        const datasetIndex = dataObj.datasets.findIndex(({ label }) => label === `${instrumentName}-${instrumentLabel}`);

        const currentIndex = index + amountOfDataSkips;

        dataObj.datasets[datasetIndex].data = [
          ...dataObj.datasets[datasetIndex].data.slice(0, currentIndex),
          ...filler,
          ...dataObj.datasets[datasetIndex].data.slice(currentIndex),
        ];

        amountOfDataSkips += numberOfIntervalsMissing;
      }
    });
  });

  let amountOfLabelSkips = 0;

  labelDates.forEach((date, index) => {
    if (index === 0) return;

    const currentDate = date.getTime();
    const prevDate = labelDates[index - 1].getTime();

    if (Math.abs(currentDate - prevDate) !== tenMinutesInMilliseconds) {
      const diffInMinutes = Math.abs(currentDate - prevDate) / (60 * 1000);
      const numberOfIntervalsMissing = (diffInMinutes / 10) - 1;

      const filler = Array(numberOfIntervalsMissing).fill(null);

      const labelsFiller = filler.map((val, labelsFillerIndex) => {
        const amountToAdd = (tenMinutesInMilliseconds * (labelsFillerIndex + 1));
        const currentFillerDate = prevDate + amountToAdd;

        return padDate(new Date(currentFillerDate));
      });

      const currentIndex = index + amountOfLabelSkips;

      dataObj.labels = [
        ...dataObj.labels.slice(0, currentIndex),
        ...labelsFiller,
        ...dataObj.labels.slice(currentIndex),
      ];

      amountOfLabelSkips += numberOfIntervalsMissing;
    }
  });

  const adjustedLabels = dataObj.labels.map((labelString, index) => {
    const [day, month, year, hours, minutes] = labelString.split(/[/: ]/);

    const date = new Date(year, parseInt(month) - 1, day, hours, minutes, 0);

    return date.toISOString();
  });

  return {
    data: {
      ...dataObj,
      labels: adjustedLabels,
    },
    yAxes,
  };
};

export const getRadarData = async (
  rawData,
  dataTypes,
  instruments,
  windVaneId,
) => {
  const mod360 = (value) => (value < 360 ? value : mod360(value - 360));

  const dataObj = {
    labels: [],
    datasets: [],
  };

  const amount = 30;
  const nonSkipAmount = 12;

  for (let i = 0; i < amount; i += 1) {
    const label = (360 / amount) * i;

    if (label % (360 / nonSkipAmount) === 0) {
      dataObj.labels.push(`${label}º`);
    } else {
      dataObj.labels.push(null);
    }
  }

  dataTypes.forEach(({ id, labels }) => {
    const instrumentData = rawData.filter((measurement) => measurement.instrument_id === id);

    const {
      name: instrumentName,
      instrument_type_id: instrumentTypeId,
    } = instruments.find((instrument) => instrument.id === id);
    if (instrumentTypeId !== windVaneId) return;

    labels.forEach((dataType) => {
      const quantityArray = dataObj.labels.map(() => 0);

      instrumentData.forEach((measurement) => {
        const value = Number(measurement[`${dataType}_value`]);
        if (value < 0) return;
        const modValue = mod360(value);
        const multiple = 360 / amount;

        const remainder = modValue % multiple;
        const roundUp = remainder === 0 ? modValue : modValue + multiple - remainder;

        const index = (roundUp * amount) / 360;

        quantityArray[index] += 1;
      });

      const totalItems = quantityArray.reduce((a, b) => a + b, 0);

      const percentageArray = quantityArray.map((num) => {
        const percentage = (num * 100) / totalItems;
        return (Math.round(percentage * 100) / 100).toFixed(2);
      });

      const instrumentTypeName = instrumentName.replace(/[^A-Za-z]/g, '');
      const instrumentIndex = instrumentName.replace(/[^0-9]/g, '');

      const name = `${instrumentName}-${dataType}`;
      const mainColor = colorsObj[instrumentTypeName][dataType][
        instrumentIndex ? instrumentIndex - 1 : 0
      ];

      dataObj.datasets.push({
        label: name,
        fill: false,
        lineTension: 0,
        pointRadius: 0,
        borderWidth: 1,
        pointHitRadius: 5,
        pointHoverBorderColor: 'white',
        borderColor: mainColor,
        backgroundColor: mainColor,
        data: percentageArray,
      });
    });
  });

  return dataObj;
};

export const getScatterData = async (rawData, selected) => {
  if (Object.keys(selected).length === 0) return null;

  const dataObj = {};
  const datasetData = [];

  rawData.forEach((data) => {
    if (dataObj[data.instrument_id]) {
      dataObj[data.instrument_id].push({
        name: data.name,
        value: data.avg_value,
        date: data.measurement_date,
      });
    } else {
      dataObj[data.instrument_id] = [{
        name: data.name,
        value: data.avg_value,
        date: data.measurement_date,
      }];
    }
  });

  const aDataObj = dataObj[selected.instrumentA.value];
  const bDataObj = dataObj[selected.instrumentB.value];

  if (!aDataObj || !bDataObj) return null;

  const bObjDateIndex = {};

  bDataObj.forEach((data) => {
    bObjDateIndex[data.date] = data.value;
  });

  aDataObj.forEach((dataA) => {
    const bDataCorrespondentValue = bObjDateIndex[dataA.date];
    if (bDataCorrespondentValue) {
      datasetData.push({
        x: Number(dataA.value).toFixed(2),
        y: Number(bDataCorrespondentValue).toFixed(2),
      });
    }
  });

  const data = {
    datasets: [{
      label: 'Comparação',
      fill: false,
      lineTension: 0,
      pointRadius: 1,
      borderWidth: 1,
      pointHitRadius: 5,
      pointHoverBorderColor: 'white',
      borderColor: 'red',
      backgroundColor: 'red',
      data: datasetData,
    }],
  };

  const xAxes = [{
    type: 'linear',
    position: 'bottom',
    scaleLabel: {
      display: true,
      labelString: `${selected.towerA.label} - ${selected.instrumentA.label}`,
    },
  }];

  const yAxes = [{
    type: 'linear',
    position: 'left',
    scaleLabel: {
      display: true,
      labelString: `${selected.towerB.label} - ${selected.instrumentB.label}`,
    },
  }];

  return {
    data,
    xAxes,
    yAxes,
  };
};
