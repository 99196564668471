import request from './request';

export const create = async ({ body }) => request.authorized().post('/instrument-types', body);

export const get = async ({ filters }) => (
  request.authorized().get('/instrument-types', {
    params: {
      ...filters && ({ filter: JSON.stringify(filters) }),
    },
  })
);

export const getOne = async ({ id, filters }) => (
  request.authorized().get(`/instrument-types/${id}`, {
    params: {
      ...filters && ({ filter: JSON.stringify(filters) }),
    },
  })
);

/**
 * A deleted stock instrument is an instrument that belongs to a
 * tower and don't belongs to a company
 */
export const del = async ({ id }) => request.authorized().delete(`/instrument-types/${id}`);
export const edit = async ({ id, value }) => request.authorized().patch(`/instrument-types/${id}`, { name: value });
