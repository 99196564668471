import React, { useState, useContext } from 'react';
import moment from 'moment';

import { DataAPI, IncidencesAPI } from 'api';
import { chunk } from 'helpers/analysis';

const AnalysisContext = React.createContext();

function AnalysisProvider({ children }) {
  const [analysisInfo, setAnalysisInfo] = useState({
    towerId: '',
    datePeriod: ['', ''],
    instruments: [],
    instrumentComparison: {},
    sidebarVariant: 'variable',
    towersRequestStrategy: 'normal',
    whiteList: null,
    refetchCounter: 0,
    selectedHeight: null,
  });

  const analyzeIncidence = async (incidenceId) => {
    try {
      const { data } = await IncidencesAPI.getOne({ id: incidenceId });

      const relatedData = data.metadata?.occurrences?.map((occurrency) => {
        if (occurrency.data_b) {
          return [
            { id: occurrency.data_a.data_id, type: occurrency.data_a.data_type },
            { id: occurrency.data_b.data_id, type: occurrency.data_b.data_type },
          ];
        }
        return { id: occurrency.data_a.data_id, type: occurrency.data_a.data_type };
      }).flat();

      const limit = 200;

      const dataRequisitions = chunk(relatedData, limit);

      const dataModelsRequisitions = dataRequisitions.map(async (data) => (
        (await DataAPI.get({
          filters: {
            where: {
              or: data.map(({ id }) => ({ id })),
            },
            include: [
              {
                relation: 'instrument',
              },
            ],
            order: ['measurement_date ASC'],
          },
        })).data
      ));

      const flattenedDataModels = (await Promise.all(dataModelsRequisitions)).flat();

      const dataModels = flattenedDataModels.sort((a, b) => {
        if (new Date(a.measurement_date) < new Date(b.measurement_date)) {
          return -1;
        }
        if (new Date(a.measurement_date) > new Date(b.measurement_date)) {
          return 1;
        }

        return 0;
      });

      const relatedInstruments = dataModels.map(({ instrument }) => instrument);

      const towerId = dataModels[0].instrument.tower_id;

      const measurementDates = dataModels.map((dataModel) => dataModel.measurement_date);

      const padding = 1;

      const datePeriod = [
        moment(measurementDates[0])
          .subtract(padding, 'hours')
          .add({ hours: 3 })
          .format('DD/MM/YYYY HH:mm'),
        moment(measurementDates[measurementDates.length - 1])
          .add(padding, 'hours')
          .add({ hours: 3 })
          .format('DD/MM/YYYY HH:mm'),
      ];

      let instruments = [];
      let selectedTypes = [];

      relatedData.forEach(({ id, type }) => {
        const {
          instrument_id: instrumentId,
        } = dataModels.find((dataModel) => dataModel.id === id);

        const instrument = relatedInstruments.find((relatedInstrument) => (
          relatedInstrument.id === instrumentId
        ));

        let modified = false;

        if (selectedTypes.length >= 2) return;

        selectedTypes = [
          ...new Set([
            ...selectedTypes,
            instrument.instrument_type_id,
          ]),
        ];

        instruments = instruments.map((addedInstrument) => {
          if (addedInstrument.id === instrument.id) {
            modified = true;
            return ({
              id: addedInstrument.id,
              labels: [
                ...new Set([
                  ...addedInstrument.labels,
                  type,
                ]),
              ],
            });
          }
          return addedInstrument;
        });

        if (!modified) {
          instruments = [
            ...instruments,
            { id: instrument.id, labels: [type] },
          ];
        }
      });

      setAnalysisInfo((prevInfo) => ({
        ...prevInfo,
        towerId,
        datePeriod,
        instruments,
        fromIncidence: true,
      }));
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <AnalysisContext.Provider value={[analysisInfo, setAnalysisInfo, analyzeIncidence]}>
      {children}
    </AnalysisContext.Provider>
  );
}

export const useAnalysis = () => useContext(AnalysisContext);

export default AnalysisProvider;
