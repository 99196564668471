import styled, { css, keyframes } from 'styled-components';
import colors from 'styles/colors';
import easing from 'styles/easing';

import { Checkmark, Close } from 'components/icons';

const iconMargin = '16px';

const openCard = keyframes`
  0% {
    max-width: 0;
    padding: ${iconMargin} 0;
  }
  100% {
    max-width: 35rem;
    padding: ${iconMargin};
  }
`;

const closeCard = keyframes`
  0% {
    height: 4rem;
    padding: ${iconMargin};
  }
  90% {
    margin: 5px 0;
  }
  100% {
    height: 0;
    margin: 0;
    padding: 0 ${iconMargin};
  }
`;

export const List = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
`;

export const CardBase = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${easing}

  max-width: 35rem;
  height: 4rem;

  border-radius: 9px 0 0 9px;
  padding: ${iconMargin};
  padding-right: 0px;
  margin: 5px 0;
  overflow: hidden;

  background-color: ${({ bgColor }) => bgColor || colors.error};
  color: ${colors.white};

  transform-origin: 100% 50%;
  animation: .2s ease-out 0s 1 forwards ${openCard};
  ${({ active }) => active !== null && !active && css`
    animation: .2s ease-out 0s 1 forwards ${closeCard};
  `}

  &:hover {
    button {
      width: 20px;
      margin-right: 20px;
    }
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 25rem;
`;

export const IconContainer = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background-color: ${({ bgColor }) => bgColor || colors.error};
  margin: 0 ${iconMargin} 0 0;
`;

export const StyledCheck = styled(Checkmark)`
  svg {
    width: 35px;
    height: 35px;
  }
`;

export const StyledClose = styled(Close)`
  svg {
    width: 20px;
    height: 20px;
  }
`;

export const CloseButton = styled.button`
  border: none;
  background-color: transparent;
  padding: 0;
  outline: none;

  display: flex;
  align-items: center;
  justify-content: center;

  ${easing}
  width: 0;
  overflow: hidden;
  cursor: pointer;
`;
