import React from 'react';
import Icon from 'components/icons/Icon';

import { ReactComponent as DataAnalysisIcon } from 'assets/icons/data-analysis.svg';

const DataAnalysisOutlined = (props) => (
  <Icon {...props} component={DataAnalysisIcon} />
);

export default DataAnalysisOutlined;
