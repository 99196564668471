import request from './request';

/**
 * Get all options for analysis selects
 * @param {Object} options
 */
export const fetchOptions = async () => {
  const [
    towerRes,
    instrumentRes,
  ] = await Promise.all([
    request.authorized().get('/towers'),
    request.authorized().get('/instruments'),
  ]);

  const towers = towerRes.data.map(({ id, name }) => ({ value: id, label: name }));
  const instruments = instrumentRes.data.map(({ id, name, tower_id: towerId }) => ({
    value: id,
    label: name,
    towerId,
  }));

  return {
    towers,
    instruments,
  };
};

/**
 * Get data
 */
export const get = async (id, { filters }) => request.authorized().get(`/data/${id}`, {
  params: {
    filter: filters ?? JSON.stringify(filters),
  },
});
