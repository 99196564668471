import request from './request';

/**
 * Get all companies
 * @param {Object} options
 */
export const get = async ({ filters } = {}) => (
  request.authorized().get('/companies', {
    params: {
      ...filters && ({ filter: JSON.stringify(filters) }),
    },
  })
);

export const getOne = async ({ id, filters }) => (
  request.authorized().get(`/companies/${id}`, {
    params: {
      ...filters && ({ filter: JSON.stringify(filters) }),
    },
  })
);

export const count = async ({ where }) => (
  request.authorized().get('/companies/count', {
    params: {
      where: JSON.stringify(where),
    },
  })
);

export const create = async (body) => request.authorized().post('/companies', body);

export const postInstrumentStock = async ({ id, body }) => (
  request.authorized().post(`/companies/${id}/instrument-stocks`, body)
);

export const postTowerStock = async ({ id, body }) => (
  request.authorized().post(`/companies/${id}/tower-stocks`, body)
);

export const edit = async ({ id, value }) => request.authorized().patch(`/companies/${id}`, { name: value });

export const remove = async ({ id }) => request.authorized().delete(`/companies/${id}`);
